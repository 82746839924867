import React from "react";
import styled from "styled-components";
import Steps from "./Steps";
import one from "./ASSESTS/COMPONENTS/insta.avif";
import two from "./ASSESTS/COMPONENTS/whats.avif";
import three from "./ASSESTS/COMPONENTS/face1.avif";
import four from "./ASSESTS/COMPONENTS/mail.avif";

const Follow = () => {
  return (
    <div>
      <Container>
        <Wrapper>
          <Steps
            col="#FEE2E2"
            bg="#FEE2E2"
            img={one}
            title="Follow Us on Instagram"
            sub="visit our page on Instagram"
            step="Instagram"
            link="https://www.gmail.com/yourinstagram"
   
          />
          <Steps
            col="#E0E7FF"
            bg="#E0E7FF"
            img={two}
            title="Order On Whatsapp"
            sub="
            send a direct message to us"
            step="WhatsApp"
            link="https://wa.me/+2348032234434"
           
          />
          <Steps
            col="#FEF9C3"
            bg="#FEF9C3"
            img={three}
            title="Follow Us on Facebook"
            sub="visit our page on facebook"
            step="Facebook"
            link="https://www.facebook.com/yourinstagram"
           
          />
          <Steps
            col="#F3E8FF"
            bg="#F3E8FF"
            img={four}
            title="Mail"
            sub="send an email to us"
            step="Mail"
            link="https://www.gmail.com/yourinstagram"
        
          />
        </Wrapper>
      </Container>
    </div>
  );
};

export default Follow;

const Wrapper = styled.div`
  display: flex;
  width: 100%;

  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  width: 100%;
  /* height: 500px; */
  /* background-color: red; */
  display: flex;

  justify-content: center;
  align-items: center;
`;
